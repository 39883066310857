.wrapper {
  background: linear-gradient(268.67deg, #f9fbff 83.03%, #dceafd 105.3%);
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logo {
  max-height: 45px !important;
  width: auto !important;
  margin-right: 48px;
}

.copyContainer {
  display: flex;
  align-items: center;
}

.description {
  font-size: 0.875rem;
  margin-right: 1.5rem;
  font-weight: 500;
  max-width: 500px;
  line-height: 1.2;
}

.bannerButton {
  min-width: 135px !important;
  padding: 5px 5px;
  font-size: 0.7rem;
}

.graphicBlock {
  padding-bottom: 10px;
  padding-right: 20px;
  display: flex;
  width: 40%;
}

.background {
  background-color: transparent;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

.copyBlock {
  padding-left: 20px;
  display: flex;
  align-items: center;
  /* max-width: 54%; */
  font-size: 1.25rem;
  min-width: 400px;
  color: var(--blue-900) !important;
}

.selectorWrapper {
  margin-left: auto;
  justify-content: flex-end;
  align-self: flex-end;
}

.mcfxCustomer360 {
  background-image: url('../../../assets/images/product-banners/customer360@1x.png');
}

.mcfxLeadScorer {
  background-image: url('../../../assets/images/product-banners/banner_leadscorer@1x.png');
}
.mcfxProspector,
.mcfxDataOS {
  flex: auto;
  min-width: 40%;
  width: unset;
  background-size: auto;
  background-position: right;
}
.mcfxDataOS {
  background-image: url('../../../assets/images/product-banners/banner_dataos.png');
}
.mcfxProspector {
  background-image: url('../../../assets/images/product-banners/banner_prospector.png');
}

.crm-pipelines,
.martech-pipelines,
.mcfxIntegrationsMartechpipelines,
.mcfxIntegrations {
  background-image: url('../../../assets/images/product-banners/banner_integrations@1x.png');
}

.audience-pipelines {
  background-image: url('../../../assets/images/product-banners/banner_integrations_audience@1x.png');
}

.lead-pipelines {
  background-image: url('../../../assets/images/product-banners/banner_integrations_lead@1x.png');
}

.data-warehouse {
  background-image: url('../../../assets/images/product-banners/banner_intregrations-warehouse@1x.png');
}

.mcfxAnalytics,
.mcfxAnalyticsReports,
.mcfxAnalyticsV1Configure {
  background-image: url('../../../assets/images/product-banners/banner_analytics@1x.png');
}

.mcfxContentAnalytics {
  background-image: url('../../../assets/images/product-banners/banner_contentAnalytics@1x.png');
}

.mcfxCompanyTracker {
  background-image: url('../../../assets/images/product-banners/banner_companytracker@1x.png');
}

.mcfxLocal {
  background-image: url('../../../assets/images/product-banners/banner_local@1x.png');
}

.mcfxAdTech {
  background-image: url('../../../assets/images/product-banners/banner_adtech@1x.png');
}

.mcfxLeadManager {
  background-image: url('../../../assets/images/product-banners/banner_leadmanager@1x.png');
}

.mcfxEmailMarketing {
  background-image: url('../../../assets/images/product-banners/banner_emailmarketing@1x.png');
}

.cgfxKanban,
.cgfxReview {
  background-image: url('../../../assets/images/product-banners/banner_contentgenius@1x.png');
}

.competitorspy,
.csfxOverview {
  background-image: url('../../../assets/images/product-banners/banner_competitorspy@1x.png');
}

.mcfxFunnels {
  background-image: url('../../../assets/images/product-banners/banner_funnels@1x.png');
}

.mcfxDeepLearning {
  background-image: url('../../../assets/images/product-banners/banner_deeplearning@1x.png');
}

.mcfxReviewBoost {
  background-image: url('../../../assets/images/product-banners/banner_reviewacceleration@1x.png');
}

.pbfxLanding,
.pbfxSalesEstimator,
.pbfxOpportunities,
.pbfxRevenue,
.pbfxStrategies,
.pbfxIndustryOverview {
  background-image: url('../../../assets/images/product-banners/banner_playbook@1x.png');
}

.mcfxCallTracker,
.mcfxNumberPool {
  background-image: url('../../../assets/images/product-banners/banner_calltraccker@1x.png');
}

.mcfxAiConversations,
.mcfxAiConversationsAiAutomations,
.mcfxConversationIntel,
.mcfxConversationIntelAiAutomations {
  background-image: url('../../../assets/images/product-banners/banner_conversationintel@1x.png');
}

.crmSettings,
.crmReports,
.crmContacts,
.crmAgenda,
.pipeline,
.crmDashboard {
  background-image: url('../../../assets/images/product-banners/banner_crmroi@1x.png');
}

.mcfxDashboard,
.mcfxSites,
.mcfxSettings,
.mcfxBaseDashboard {
  background-image: url('../../../assets/images/product-banners/banner_marketingcloud@1x.png');
}

.mcfxScheduler {
  background-image: url('../../../assets/images/product-banners/banner_scheduler@1x.png');
}

.projectHistory {
  background-image: url('../../../assets/images/product-banners/banner_history@1x.png');
}

.projectTodolists {
  background-image: url('../../../assets/images/product-banners/banner_todos@1x.png');
}

.projectMilestones {
  background-image: url('../../../assets/images/product-banners/banner_milestones@1x.png');
}

@media only screen and (max-width: 1612px) {
  .moreText {
    display: none;
  }
  .logo {
    margin-right: 36px;
    max-height: 40px !important;
  }
  .mcfxProspector,
  .mcfxDataOS {
    background-position: left;
  }
}

@media only screen and (max-width: 1366px) {
  .copyBlock {
    padding-bottom: 5px;
    max-width: max-content;
  }

  .description {
    margin-right: 8px;
    font-size: 0.8rem;
  }

  .logo {
    margin-right: 24px;
    max-height: 35px !important;
  }

  .withButton {
    max-width: 350px;
  }
}

@media only screen and (max-width: 1080px) {
  .copyBlock {
    flex-direction: column;
    align-items: start;
  }
  .logo {
    margin-right: 10px;
    margin-bottom: 8px;
    margin-top: 3px;
  }
  .wrapper {
    height: auto;
  }

  .description {
    font-size: 0.8rem;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  /* Retina-specific stuff here */
  .projectHistory {
    background-image: url('../../../assets/images/product-banners/banner_history@3x.png');
  }

  .projectTodolists {
    background-image: url('../../../assets/images/product-banners/banner_todos@3x.png');
  }

  .projectMilestones {
    background-image: url('../../../assets/images/product-banners/banner_milestones@3x.png');
  }

  .mcfxCustomer360 {
    background-image: url('../../../assets/images/product-banners/customer360@3x.png');
  }

  .mcfxLeadScorer {
    background-image: url('../../../assets/images/product-banners/banner_leadscorer@3x.png');
  }

  .crm-pipelines,
  .martech-pipelines,
  .mcfxIntegrationsMartechpipelines,
  .mcfxIntegrations {
    background-image: url('../../../assets/images/product-banners/banner_integrations@3x.png');
  }

  .data-warehouse {
    background-image: url('../../../assets/images/product-banners/banner_intregrations-warehouse@3x.png');
  }

  .mcfxAnalytics,
  .mcfxAnalyticsReports,
  .mcfxAnalyticsV1Configure {
    background-image: url('../../../assets/images/product-banners/banner_analytics@3x.png');
  }

  .mcfxCompanyTracker {
    background-image: url('../../../assets/images/product-banners/banner_companytracker@3x.png');
  }

  .mcfxLocal {
    background-image: url('../../../assets/images/product-banners/banner_local@3x.png');
  }

  .mcfxAdTech {
    background-image: url('../../../assets/images/product-banners/banner_adtech@3x.png');
  }

  .mcfxLeadManager {
    background-image: url('../../../assets/images/product-banners/banner_leadmanager@3x.png');
  }

  .mcfxEmailMarketing {
    background-image: url('../../../assets/images/product-banners/banner_emailmarketing@3x.png');
  }

  .cgfxKanban,
  .cgfxReview {
    background-image: url('../../../assets/images/product-banners/banner_contentgenius@3x.png');
  }

  .competitorspy,
  .csfxOverview {
    background-image: url('../../../assets/images/product-banners/banner_competitorspy@3x.png');
  }

  .mcfxFunnels {
    background-image: url('../../../assets/images/product-banners/banner_funnels@3x.png');
  }

  .mcfxDeepLearning {
    background-image: url('../../../assets/images/product-banners/banner_deeplearning@3x.png');
  }

  .mcfxReviewBoost {
    background-image: url('../../../assets/images/product-banners/banner_reviewacceleration@3x.png');
  }

  .mcfxCPLDashboard {
    background-image: url('../../../assets/images/product-banners/banner_cpldashboard@3x.png');
  }

  .pbfxLanding,
  .pbfxSalesEstimator,
  .pbfxOpportunities,
  .pbfxRevenue,
  .pbfxStrategies,
  .pbfxIndustryOverview {
    background-image: url('../../../assets/images/product-banners/banner_playbook@3x.png');
  }

  .mcfxCallTracker,
  .mcfxNumberPool {
    background-image: url('../../../assets/images/product-banners/banner_calltraccker@3x.png');
  }

  .mcfxCallerAnalytics {
    background-image: url('../../../assets/images/product-banners/banner_conversationintel@3x.png');
  }

  .crmSettings,
  .crmReports,
  .crmContacts,
  .crmAgenda,
  .pipeline,
  .crmDashboard {
    background-image: url('../../../assets/images/product-banners/banner_crmroi@3x.png');
  }

  .mcfxDashboard,
  .mcfxSites,
  .mcfxSettings,
  .mcfxBaseDashboard {
    background-image: url('../../../assets/images/product-banners/banner_marketingcloud@3x.png');
  }

  .mcfxScheduler {
    background-image: url('../../../assets/images/product-banners/banner_scheduler@3x.png');
  }

  .audience-pipelines {
    background-image: url('../../../assets/images/product-banners/banner_integrations_audience@3x.png');
  }

  .lead-pipelines {
    background-image: url('../../../assets/images/product-banners/banner_integrations_lead@3x.png');
  }
}
