.header {
  position: relative;
  z-index: 1049;
  width: 100vw;
  height: 70px;
}

.header:has(:global(#viewing-as-alert)) {
  height: 120px !important;
}

.appLogo {
  background-color: #263a5f;
}

.toggleButton {
  height: 100%;
  min-width: 40px;
  border-radius: 0;
  border-right: 1px solid #00448f;
}

.toggleButton.active {
  background-color: var(--blue-400);
}

.toggleButton:hover {
  background-color: var(--blue-vivid-300);
}

.toggleButton:focus {
  text-decoration: none;
  box-shadow: none;
}

.menu {
  display: flex;
  position: relative;
  align-items: center;
  height: 70px;
  background-color: var(--blue-400);
  color: var(--light);
}

.appLogo :global(.link) {
  min-height: 70px;
  max-height: 70px;
  /* Temporary fix for styling issues in master */
  align-content: center;
  /* line-height: 70px; */
}

.toggleLogo {
  background-color: #263a5f;
  display: block;
  padding: 0 10.5px 0 0;
}

.logo {
  padding: 0 45px;
  background-color: #263a5f;
  display: none;
}

.logo:hover {
  background-color: var(--blue-400);
}

.smallLogo {
  padding: 0 15.5px;
  background-color: #263a5f;
  display: block;
}

@media only screen and (min-width: 1366px) {
  .logo {
    display: block;
  }

  .smallLogo {
    display: none;
  }
}

.toggleLogo.logo {
  padding: 0 25px;
}

.localLogo {
  background-color: palegreen;
}

.devLogo {
  background-color: deeppink;
}

.nextLogo {
  background-color: orangered;
}

.tabs {
  display: none;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}

.tabsScrollWrap {
  display: flex;
}

@media only screen and (min-width: 600px) {
  .tabs {
    display: flex;
  }
}

.mobileDropdown {
  display: none;
  flex-grow: 1;
  min-height: 70px;
}

.mobileDropdown :global(.toggle-button) {
  height: 70px;
  line-height: 70px;
  font-size: 24px;
}

.mobileDropdown :global(.toggle-button:after) {
  display: none;
}

.mobileDropdown :global(.toggle-button:hover) {
  background-color: rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 600px) {
  .mobileDropdown {
    display: block;
  }
}

.mobileDropdownMenu {
  top: 70px !important;
  border: none;
  width: 101vw;
  margin-left: -70px !important;
  border-radius: 0;
  height: calc(100vh - 70px);
  transform: none !important;
  background-color: var(--blue-400);
  padding-top: 0;
}

.mobileTab {
  position: relative;
  padding: 0;
}

.mobileTab :global(a) {
  padding: 28px;
  margin-bottom: 0;
}

.mobileTab :global(.arrow-icon) {
  position: absolute;
  right: 40px;
  top: 33px;
  color: var(--white);
}

.mobileTab:hover {
  background-color: var(--blue-400) !important;
}

.mobileTab:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.globalPermissions {
  opacity: 1;
}

.profile {
  padding-right: 26px;
}

@media only screen and (min-width: 1366px) {
  .profile {
    padding-right: 42px;
  }
}

@media only screen and (max-width: 430px) {
  .profile {
    padding-right: 15px;
  }
}
